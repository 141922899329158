
import { ref, onMounted, defineComponent } from 'vue';
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import LocalEchoController from 'local-echo';
import 'xterm/css/xterm.css';
import IconCode from '@/components/icons/IconCode.vue';
import {
  props,
  components as viewComponents,
  emits,
  sizes,
} from '@/mixins/view';
import { Baesh } from '@/util/baesh';
import { useStore } from '@/store';

export default defineComponent({
  components: {
    ...viewComponents,
    IconCode,
  },
  props,
  emits,
  setup(props, { emit }) {
    const store = useStore();
    const el = ref<HTMLElement | null>(null);
    const term = new Terminal({ fontFamily: 'Based', fontSize: 18 });
    const fitAddon = new FitAddon();
    const localEcho = new LocalEchoController();
    const baesh = new Baesh(store);
    const promptText = '> ';
    term.loadAddon(fitAddon);
    term.loadAddon(localEcho);
    const onDismiss = () => emit('dismiss');
    const onResize = () => {
      fitAddon.fit();
    };
    onMounted(() => {
      setTimeout(() => {
        if (el.value) term.open(el.value);

        function read() {
          localEcho
            .read(promptText)
            .then((input: string) => {
              const result = baesh.execute(input);
              if (result) localEcho.println(result);
              read();
            })
            .catch((error: string) => {
              if (error) localEcho.println(error);
              else localEcho.print('\x1bc');
              read();
            });
        }
        localEcho.println(baesh.versionStr());
        localEcho.println('Use "help" to see a list of commands.');
        read();

        fitAddon.fit();
        if (el.value) {
          const textarea = el.value.querySelector<HTMLInputElement>(
            '.xterm-helper-textarea'
          );
          if (textarea) {
            textarea.focus();
          }
        }
      }, 1000);
    });
    return { el, onResize, onDismiss, sizes: sizes() };
  },
});
