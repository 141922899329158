import { Instance as ChalkInstance } from 'chalk';
import { cartCount, cartTotal, formatPrice } from '@/util/helpers';
import { saveCheckoutTimestamp } from '@/util/local-storage';
import { Store } from '@/types/store';

const matchStr = (str1: string, str2: string) =>
  str1.toLowerCase() === str2.toLowerCase();

const clr = new ChalkInstance({ level: 1 });

class Baesh {
  commandsPublic: Array<string>;
  commandsPrivate: Array<string>;
  store: Store;
  version: string;

  constructor(store: Store) {
    this.commandsPublic = [
      'bounce',
      'cart',
      'checkout',
      'clear',
      'echo',
      'mirrormode',
      'product',
      'reset',
      'shake',
      'shutdown',
      'speed',
    ];

    this.commandsPrivate = ['credits', 'pewdiepie', 'help'];
    this.store = store;
    this.version = '0.1.1009';
  }

  versionStr(): string {
    return `Baesh [Version ${this.version}]`;
  }

  parseLine(command: string): RegExpMatchArray {
    const regex = /"(.*?)"|(\S+)/g;
    const commands = [];
    let m;
    while ((m = regex.exec(command)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) regex.lastIndex++;
      const match = m[1] || m[2];
      commands.push(match);
    }
    return commands;
  }

  execute(line: string): string | void {
    if (!line) return undefined;
    const commands = this.parseLine(line);
    // eslint-disable-next-line
    let context = this as any;
    let i = 0;
    for (const command of commands) {
      const location = context[command];
      if (location === undefined) break;
      context = location;
      i += 1;
    }
    const fn = typeof context === 'function' ? context : context.default;
    if (
      fn &&
      typeof fn === 'function' &&
      [...this.commandsPublic, ...this.commandsPrivate].includes(commands[0])
    ) {
      return fn.apply(this, commands.slice(i));
    }
    return this.notFound(commands[0]);
  }

  notFound(commandName: string): void {
    throw `-baesh: ${clr.red(commandName)}: command not found. Try "help".`;
  }

  findItem(this: Baesh, productStr = '', variantStr = '') {
    if (!productStr) throw 'Must provide a product title.';
    const products = this.store.state.products;
    if (products) {
      const product = products.find(
        (p) => matchStr(productStr, p.handle) || matchStr(productStr, p.title)
      );
      if (!product) throw `Could not find product ${productStr}.`;
      if (product.variants.length > 1 && !variantStr) {
        throw `Must provide a variant title for ${product.title}.`;
      }
      const variant =
        product.variants.length === 1
          ? product.variants[0]
          : product.variants.find((v) => matchStr(variantStr, v.title));
      if (!variant) throw `Could not find variant ${variantStr}.`;
      return { product, variant };
    }
    return undefined;
  }

  findItemInCart(this: Baesh, productStr = '', variantStr = '') {
    if (!productStr) throw 'Must provide a product title.';
    if (!variantStr) throw 'Must provide a variant title.';
    const item = this.store.state.cart.find(
      (p) =>
        (matchStr(productStr, p.productHandle) ||
          matchStr(productStr, p.productTitle)) &&
        matchStr(variantStr, p.variantTitle)
    );
    if (!item) {
      throw `Could not find product ${productStr} with variant ${variantStr}.`;
    }
    return item;
  }

  pewdiepie() {
    window.location.href = 'https://www.youtube.com/user/PewDiePie';
    return 'Going to YouTube...';
  }

  credits() {
    return [
      'Designed & built by',
      ' ',
      '  █▄▄▄▄     ██▄     ▄      ▄   █  █▀',
      '  █  ▄▀     █  █     █      █  █▄█  ',
      '  █▀▀▌      █   █ █   █ ██   █ █▀▄  ',
      '  █  █      █  █  █   █ █ █  █ █  █ ',
      '    █       ███▀  █▄ ▄█ █  █ █   █  ',
      '   ▀               ▀▀▀  █   ██  ▀   ',
      ' ',
      'Get in touch. rupertdunk.com',
    ].join('\n');
  }

  // Commands
  help = {
    default(this: Baesh) {
      const commands = this.commandsPublic.filter((command) => command in this);
      return [
        this.versionStr(),
        'Available commands listed below.',
        "Type 'help [name]' to see more about a specific command.",
        ' ',
        ...commands,
      ].join('\n');
    },
    bounce() {
      return 'Adds a hilariously exciting bouncing thing to the desktop.';
    },
    speed() {
      return 'Returns the current speed. Type speed [number] to set the speed.';
    },
    mirrormode() {
      return 'Make the website really annoying to use.';
    },
    shake() {
      return 'Give yourself motion sickness.';
    },
    reset() {
      return 'Make everything normal again.';
    },
    clear() {
      return 'Clear the terminal screen.';
    },
    cart() {
      return [
        'Manage your cart',
        '  cart add [product] [variant]',
        '  cart clear',
        '  cart contents',
        '  cart remove [product] [variant]',
        '  cart total',
      ].join('\n');
    },
    product() {
      return [
        'Get information about products and variants',
        '  product [product]',
        '  product list',
      ].join('\n');
    },
    echo() {
      return 'Output some text.';
    },
    checkout() {
      return 'Checkout your current cart.';
    },
    shutdown() {
      return 'Shut down the website.';
    },
  };

  cart = {
    default() {
      return 'Available subcommands: add, clear, contents, remove, total.';
    },
    add(this: Baesh, productStr = '', variantStr = '') {
      if (!productStr) return 'Must provide a product title.';
      const item = this.findItem(productStr, variantStr);
      if (item) {
        const { product, variant } = item;
        if (!variant.availableForSale) {
          return `${product.title} (${variant.title}) is out of stock!`;
        }
        const added = this.store.commit.cartAdd(product, variant);
        if (added) {
          return `${product.title} (${variant.title}) added to cart.`;
        }
        return `Did not add. The maximum number of ${product.title} (${variant.title}) is already in the cart.`;
      }
    },
    clear(this: Baesh) {
      const count = cartCount(this.store.state.cart);
      this.store.commit.cartClear();
      return `${count} items removed from cart.`;
    },
    contents(this: Baesh) {
      const items = this.store.state.cart.map((item) => {
        return `${item.quantity} × ${item.productTitle} (${item.variantTitle})`;
      });
      if (items.length) return items.join('\n');
      return 'Cart is empty.';
    },
    remove(this: Baesh, productStr = '', variantStr = '') {
      if (!productStr) return 'Must provide a product title.';
      const item = this.findItemInCart(productStr, variantStr);
      this.store.commit.cartRemove(item.variantId);
      return `${item.productTitle} (${item.variantTitle}) removed from cart.`;
    },
    total(this: Baesh) {
      const total = cartTotal(this.store.state.cart, false);
      if (total) return `${formatPrice(total)} worth of stuff in the cart.`;
      return 'Cart is empty.';
    },
  };

  clear() {
    throw null;
  }

  checkout(this: Baesh) {
    const { cart } = this.store.state;
    if (!cart.length) return 'Add something to the cart before checking out.';
    const checkoutUrl = this.store.state.checkoutUrl;
    if (!checkoutUrl) return 'Something went wrong...';
    saveCheckoutTimestamp();
    window.location.href = checkoutUrl;
    return `Checking out ${cartCount(
      cart
    )} items costing a total of ${cartTotal(cart)}.`;
  }

  echo(this: Baesh, ...text: string[]): string {
    return text.join(' ');
  }

  product = {
    default(this: Baesh, name: string) {
      const products = this.store.state.products;
      if (name && products) {
        const product = products.find(
          (p) => matchStr(name, p.handle) || matchStr(name, p.title)
        );
        if (product) {
          const { title, handle, variants } = product;
          const productLine = `${title} (${handle}).`;
          const variantLines = variants.map(
            (v) =>
              `↳ ${v.title}: ${formatPrice(parseInt(v.priceV2.amount, 10))}`
          );
          return [productLine, ...variantLines].join('\n');
        }
        return `Could not find product ${name}.`;
      }
      return 'Available subcommands: [product], list.';
    },

    list(this: Baesh) {
      return (this.store.state.products || [])
        .map((p) => `${p.title} (${p.handle})`)
        .join('\n');
    },
  };

  bounce() {
    const isActive = this.store.state.backgrounds.bounce;
    this.store.commit.toggleBackground('bounce');
    return `Bounce ${isActive ? 'deactivated' : 'activated'}.`;
  }

  mirrormode() {
    const isActive = this.store.state.effects.mirror;
    this.store.commit.toggleEffect('mirror');
    return `Mirror mode ${isActive ? 'deactivated' : 'activated'}.`;
  }

  shake() {
    const isActive = this.store.state.effects.shake;
    this.store.commit.toggleEffect('shake');
    return `Shake ${isActive ? 'deactivated' : 'activated'}.`;
  }

  reset() {
    this.store.commit.setSpaceSpeed(1);
    this.store.commit.resetBackgrounds();
    this.store.commit.resetEffects();
    return 'Reset all the crap.';
  }

  speed(speedStr: string) {
    if (speedStr) {
      const speed = parseInt(speedStr, 10);
      if (isNaN(speed) || speed < -25 || speed > 25) {
        return 'Speed must be between -25 and 25.';
      }
      this.store.commit.setSpaceSpeed(speed);
      return `Speed set to ${speed}.`;
    }
    return `Speed is currently ${this.store.state.space.speed}.`;
  }

  shutdown() {
    const shutdownEvent = new Event('shutdown');
    window.dispatchEvent(shutdownEvent);
    return 'Shutting down...';
  }
}

export { Baesh };
