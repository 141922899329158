<template>
  <BaseWindow
    title="Baesh"
    container-class=""
    :scroll-y="false"
    v-bind="sizes"
    @dismiss="onDismiss"
    @resize-end="onResize"
  >
    <template #icon>
      <IconCode class="w-6" />
    </template>
    <div ref="el" class="w-full h-full bg-black" />
  </BaseWindow>
</template>

<script lang="ts">
import { ref, onMounted, defineComponent } from 'vue';
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import LocalEchoController from 'local-echo';
import 'xterm/css/xterm.css';
import IconCode from '@/components/icons/IconCode.vue';
import {
  props,
  components as viewComponents,
  emits,
  sizes,
} from '@/mixins/view';
import { Baesh } from '@/util/baesh';
import { useStore } from '@/store';

export default defineComponent({
  components: {
    ...viewComponents,
    IconCode,
  },
  props,
  emits,
  setup(props, { emit }) {
    const store = useStore();
    const el = ref<HTMLElement | null>(null);
    const term = new Terminal({ fontFamily: 'Based', fontSize: 18 });
    const fitAddon = new FitAddon();
    const localEcho = new LocalEchoController();
    const baesh = new Baesh(store);
    const promptText = '> ';
    term.loadAddon(fitAddon);
    term.loadAddon(localEcho);
    const onDismiss = () => emit('dismiss');
    const onResize = () => {
      fitAddon.fit();
    };
    onMounted(() => {
      setTimeout(() => {
        if (el.value) term.open(el.value);

        function read() {
          localEcho
            .read(promptText)
            .then((input: string) => {
              const result = baesh.execute(input);
              if (result) localEcho.println(result);
              read();
            })
            .catch((error: string) => {
              if (error) localEcho.println(error);
              else localEcho.print('\x1bc');
              read();
            });
        }
        localEcho.println(baesh.versionStr());
        localEcho.println('Use "help" to see a list of commands.');
        read();

        fitAddon.fit();
        if (el.value) {
          const textarea = el.value.querySelector<HTMLInputElement>(
            '.xterm-helper-textarea'
          );
          if (textarea) {
            textarea.focus();
          }
        }
      }, 1000);
    });
    return { el, onResize, onDismiss, sizes: sizes() };
  },
});
</script>

<style lang="postcss">
.xterm {
  font-feature-settings: 'liga' 0;
  position: relative;
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  padding: 1rem;
}

.xterm.focus,
.xterm:focus {
  outline: none;
}

.xterm .xterm-helpers {
  position: absolute;
  top: 0;
  /**
   * The z-index of the helpers must be higher than the canvases in order for
   * IMEs to appear on top.
   */
  z-index: 5;
}

.xterm .xterm-helper-textarea {
  padding: 0;
  border: 0;
  margin: 0;
  /* Move textarea out of the screen to the far left, so that the cursor is not visible */
  position: absolute;
  opacity: 0;
  left: -9999em;
  top: 0;
  width: 0;
  height: 0;
  z-index: -5;
  /* * Prevent wrapping so the IME appears against the textarea at the correct position */
  white-space: nowrap;
  overflow: hidden;
  resize: none;
}

.xterm .composition-view {
  /* TODO: Composition position got messed up somewhere */
  background: #000;
  color: #fff;
  display: none;
  position: absolute;
  white-space: nowrap;
  z-index: 1;
}

.xterm .composition-view.active {
  display: block;
}

.xterm .xterm-viewport {
  /* On OS X this is required in order for the scroll bar to appear fully opaque */
  background-color: #000;
  overflow-y: scroll;
  cursor: default;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.xterm .xterm-screen {
  position: relative;
}

.xterm .xterm-screen canvas {
  position: absolute;
  left: 0;
  top: 0;
}

.xterm .xterm-scroll-area {
  visibility: hidden;
}

.xterm-char-measure-element {
  display: inline-block;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: -9999em;
  line-height: normal;
}

.xterm {
  cursor: text;
}

.xterm.enable-mouse-events {
  /* When mouse events are enabled (eg. tmux), revert to the standard pointer cursor */
  cursor: default;
}

.xterm.xterm-cursor-pointer {
  cursor: pointer;
}

.xterm.column-select.focus {
  /* Column selection mode */
  cursor: crosshair;
}

.xterm .xterm-accessibility,
.xterm .xterm-message {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  color: transparent;
}

.xterm .live-region {
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.xterm-dim {
  opacity: 0.5;
}

.xterm-underline {
  text-decoration: underline;
}
</style>
