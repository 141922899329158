<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    shape-rendering="crispEdges"
  >
    <g fill="currentColor">
      <rect x="8" y="5" width="2" height="2" />
      <rect x="6" y="7" width="2" height="2" />
      <rect x="4" y="9" width="2" height="2" />
      <rect x="2" y="11" width="2" height="2" />
      <rect x="4" y="13" width="2" height="2" />
      <rect x="6" y="15" width="2" height="2" />
      <rect x="8" y="17" width="2" height="2" />
      <rect x="14" y="5" width="2" height="2" />
      <rect x="16" y="7" width="2" height="2" />
      <rect x="18" y="9" width="2" height="2" />
      <rect x="20" y="11" width="2" height="2" />
      <rect x="18" y="13" width="2" height="2" />
      <rect x="16" y="15" width="2" height="2" />
      <rect x="14" y="17" width="2" height="2" />
    </g>
  </svg>
</template>
